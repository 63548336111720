import Vue from "vue";
import VueRouter from "vue-router";
import ProfileForm from "@/views/ProfileForm";
import ResultsView from "@/views/ResultsView";
import BelbinTest from "@/views/belbin-test/BelbinTest";
import NotFound from "@/views/NotFound";
import Home from "@/views/Home";
import AnimalsView from "@/views/AnimalsView";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/test/:key",
    name: "BelbinTest",
    component: BelbinTest,
    props: true,
  },
  {
    path: "/test/:key/profile-form",
    name: "Profile Form",
    component: ProfileForm,
    props: true,
  },
  {
    path: "/test/:key/results",
    name: "Results",
    component: ResultsView,
    props: true,
  },
  {
    path: "/test/aited/animals",
    name: "Animals",
    component: AnimalsView,
  },
  {
    path: "/not-found",
    name: "NotFound",
    component: NotFound,
    props: true,
  },
  { path: "*", redirect: "/not-found" },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
