<template>
  <v-container class="mt-auto mb-auto">
    <v-row align="center" justify="center">
      <v-icon size="120"> sentiment_dissatisfied </v-icon>
    </v-row>
    <v-row align="center" justify="center">
      <h1>¡UPS!</h1>
    </v-row>
    <v-row align="center" justify="center">
      <span>
        Algo ha ido mal... inténtalo de nuevo más tarde. Si el problema
        persiste, contacte con el administrador.
      </span>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "NotFound",
};
</script>

<style scoped></style>
