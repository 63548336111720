<template>
  <v-footer padless inset class="py-8">
    <v-row justify="center">
      <span>
        Test animalitos - <a href="https://somosaited.org/">AITED</a>
      </span>
    </v-row>
  </v-footer>
</template>

<script>
export default {
  name: "Footer",
};
</script>
