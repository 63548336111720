<template>
  <v-container>
    <v-row class="mt-4" justify="center">
      <router-link :to="{ name: 'Home' }">
        <v-img
          max-width="150"
          contain
          :src="require('@/assets/aited.png')"
        ></v-img>
      </router-link>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "Header",
};
</script>
