<template>
  <v-container v-if="!isLoading">
    <v-row justify="center">
      <v-col cols="12" md="10" class="d-inline-flex">
        <v-btn
          class="mt-2"
          color="secondary"
          small
          :disabled="page === 1"
          @click="prev"
        >
          <v-icon class="d-inline d-md-none">arrow_back</v-icon>
          <span class="d-none d-md-inline">Anterior</span>
        </v-btn>
        <v-spacer></v-spacer>
        <v-pagination
          v-model="page"
          color="secondary"
          :length="questions.length"
          disabled
        ></v-pagination>
        <v-spacer></v-spacer>
        <v-btn
          v-if="!isLastPage"
          class="mt-2"
          color="secondary"
          :disabled="!canGoNext"
          small
          @click="next"
        >
          <v-icon class="d-inline d-md-none">arrow_forward</v-icon>
          <span class="d-none d-md-inline">Siguiente</span>
        </v-btn>
        <v-btn
          v-else
          class="mt-2"
          color="secondary"
          :disabled="!canGoNext"
          small
          @click="next"
        >
          <v-icon class="d-inline d-md-none">arrow_forward</v-icon>
          <span class="d-none d-md-inline">Finalizar</span>
        </v-btn>
      </v-col>
    </v-row>
    <v-row v-if="pointsErrorTop" justify="center">
      <v-col cols="12" md="10">
        <v-alert color="red" outlined text type="error">
          Los puntos repartidos no pueden ser más de 10
        </v-alert>
      </v-col>
    </v-row>
    <belbin-form
      id="belbin-test"
      v-model="answers[selected]"
      :question="questionSelected"
    ></belbin-form>
    <v-row justify="center">
      <v-col cols="12" md="10" class="d-inline-flex">
        <v-btn
          class="mt-2"
          color="secondary"
          small
          :disabled="page === 1"
          @click="prev"
        >
          <v-icon class="d-inline d-md-none">arrow_back</v-icon>
          <span class="d-none d-md-inline">Anterior</span>
        </v-btn>
        <v-spacer></v-spacer>
        <v-pagination
          v-model="page"
          color="secondary"
          :length="questions.length"
          disabled
        ></v-pagination>
        <v-spacer></v-spacer>
        <v-btn
          v-if="!isLastPage"
          class="mt-2"
          color="secondary"
          :disabled="!canGoNext"
          small
          @click="next"
        >
          <v-icon class="d-inline d-md-none">arrow_forward</v-icon>
          <span class="d-none d-md-inline">Siguiente</span>
        </v-btn>
        <v-btn
          v-else
          class="mt-2"
          color="secondary"
          :disabled="!canGoNext"
          small
          @click="next"
        >
          <v-icon class="d-inline d-md-none">arrow_forward</v-icon>
          <span class="d-none d-md-inline">Finalizar</span>
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
  <v-container v-else>
    <v-card>
      <loading-page label="Cargando el test..."></loading-page>
    </v-card>
  </v-container>
</template>

<script>
import BelbinForm from "@/views/belbin-test/BelbinForm";
import QuestionnairesRepository from "@/repositories/QuestionnairesRepository";
import ResultsRepository from "@/repositories/ResultsRepository";
import LoadingPage from "@/components/LoadingPage";

export default {
  name: "MainView",
  components: { LoadingPage, BelbinForm },
  props: {
    user: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      answers: [],
      loading: true,
      page: 1,
      questions: [],
    };
  },
  computed: {
    selected() {
      return this.page - 1;
    },
    questionSelected() {
      return this.questions[this.selected];
    },
    pointsErrorTop() {
      const pointsAdded = this.answers[this.selected].reduce(
        (a, b) => (a || 0) + (b || 0),
        0
      );
      return pointsAdded > 10;
    },
    pointsErrorBottom() {
      const pointsAdded = this.answers[this.selected].reduce(
        (a, b) => (a || 0) + (b || 0),
        0
      );
      return pointsAdded < 10;
    },
    isLastPage() {
      return this.page === this.questions.length;
    },
    canGoNext() {
      // The user cannot go to the next question if the total points distributed between the answers is over 10
      return !this.pointsErrorTop && !this.pointsErrorBottom;
    },
    isLoading() {
      return this.loading;
    },
  },
  watch: {
    questions() {
      if (this.answers == null || this.answers.length === 0) {
        this.answers = new Array(this.questions.length).fill([]);
      }
    },
  },
  created() {
    QuestionnairesRepository.findByKey(this.$route.params.key)
      .then((res) => {
        if (res !== null) {
          this.questions = res.questions;
        } else {
          this.$router.push({ name: "NotFound" });
        }
      })
      .catch(() => this.$router.push({ name: "NotFound" }))
      .finally(() => (this.loading = false));
  },
  methods: {
    prev() {
      this.page -= 1;
      this.scrollToTop();
    },
    next() {
      if (this.canGoNext) {
        if (!this.isLastPage) {
          this.page += 1;
          this.scrollToTop();
        } else {
          this.sendResults();
        }
      }
    },
    sendResults() {
      this.loading = true;
      ResultsRepository.save(this.$route.params.key, {
        data: this.answers,
        user: this.user,
      })
        .then((res) => {
          localStorage.removeItem("user");
          this.$router.push({
            name: "Results",
            params: {
              key: this.$route.params.key,
            },
            query: {
              result: res.id,
            },
          });
        })
        .catch((err) => {
          this.$notify({
            title: "Ups, algo ha ido mal",
            text: err.response.data,
            type: "error",
          });
        })
        .finally(() => (this.loading = false));
    },
    scrollToTop() {
      const yOffset = -10;
      const element = document.getElementById("belbin-test");
      const y =
        element.getBoundingClientRect().top + window.pageYOffset + yOffset;
      window.scrollTo({ top: y, behavior: "smooth" });
    },
  },
};
</script>

<style>
.v-pagination--disabled {
  opacity: 1 !important;
}
</style>
