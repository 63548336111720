import axios from "axios";

export default {
  async saveUser(user) {
    return (
      await axios.get(`${process.env.VUE_APP_SERVER_URL}google-api`, {
        params: { user },
      })
    ).data;
  },
};
