<template>
  <v-container>
    <v-card v-if="data != null">
      <v-card-title>
        <v-row justify="center">
          <v-col v-if="!$route.query.admin">
            <span class="text-h3"> Resultados </span>
          </v-col>
          <v-col v-else>
            <span class="text-h3"> Resultados de {{ data.user.name }} </span>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text class="mt-4">
        <v-row class="mb-4" justify="center">
          <span> Tus animalitos son los que tienen 10 puntos o más. </span>
        </v-row>
        <v-row
          v-for="category in data.results"
          :key="category.category"
          justify="center"
        >
          <span :style="category.points >= 10 ? 'font-weight: bold' : ''">
            {{ category.category + " : " + category.points + " puntos" }}
          </span>
        </v-row>
        <v-row>
          <v-col v-if="!$route.query.admin">
            <span>
              Se ha mandado un correo electrónico a {{ data.user.email }} con
              los resultados.
            </span>
          </v-col>
          <v-col v-else class="mt-5">
            <v-row justify="center"
              ><span class="text-h5">Datos del usuario</span></v-row
            >
            <v-row justify="center">
              <span><b>Nombre: </b>{{ data.user.name }}</span>
            </v-row>
            <v-row v-if="data.user.company" justify="center">
              <span><b>Compañía: </b>{{ data.user.company }}</span>
            </v-row>
            <v-row justify="center">
              <span><b>Email: </b>{{ data.user.email }}</span>
            </v-row>
          </v-col>
        </v-row>
        <v-row justify="center">
          <v-btn class="mt-4" color="#bdd7db" @click="openAnimalsDescription">
            Ver descripción animalitos
          </v-btn>
        </v-row>
        <div class="small">
          <results-chart
            :data="chartData"
            :options="chartOptions"
          ></results-chart>
        </div>
      </v-card-text>
    </v-card>

    <v-card v-else>
      <loading-page label="Recibiendo datos..."></loading-page>
    </v-card>
  </v-container>
</template>

<script>
import ResultsChart from "@/components/ResultsChart";
import ResultsRepository from "@/repositories/ResultsRepository";
import LoadingPage from "@/components/LoadingPage";

const defaultChartOptions = {
  scales: {
    yAxes: [
      {
        ticks: {
          min: 0,
          max: 30,
        },
      },
    ],
  },
  legend: {
    display: false,
  },
};

export default {
  name: "ResultsView",
  components: { LoadingPage, ResultsChart },
  data() {
    return {
      data: null,
      chartData: {},
      chartOptions: defaultChartOptions,
    };
  },
  watch: {
    data: {
      handler() {
        if (this.data.results && this.data.results.length > 0) {
          this.chartData = {
            labels: this.data.results.map((cat) => cat.category),
            datasets: [
              {
                label: "Puntos",
                backgroundColor: this.data.results.map(() => this._getColor()),
                data: this.data.results.map((cat) => cat.points),
              },
            ],
          };
        }
      },
      deep: true,
    },
  },
  mounted() {
    ResultsRepository.find(this.$route.params.key, {
      params: { result: this.$route.query.result },
    })
      .then((res) => (this.data = res.data))
      .catch(() => this.$router.push({ name: "NotFound" }));
  },
  methods: {
    _getColor() {
      const letters = "0123456789ABCDEF";
      let color = "#";
      for (var i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
      }
      return color;
    },
    openAnimalsDescription() {
      const route = this.$router.resolve({ name: "Animals" });
      window.open(route.href);
    },
  },
};
</script>

<style scoped>
.small {
  max-width: 500px;
  margin: 50px auto;
}
.text-h3 {
  word-break: break-word;
}
</style>
