<template>
  <v-container>
    <span> Test animalitos </span>
  </v-container>
</template>

<script>
export default {
  name: "Home",
  beforeMount() {
    // This view is not being used. Redirect automatically to AITED test
    this.$router.push({
      name: "BelbinTest",
      params: {
        key: "aited",
      },
    });
  },
};
</script>
