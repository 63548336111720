<template>
  <v-col cols="12" md="10" class="d-inline-block">
    <v-row align="center">
      <v-card width="100%">
        <v-card-title
          class="text-center d-inline-block font-weight-bold text-h5"
        >
          <span> {{ question.question }} </span>
        </v-card-title>
        <v-card-text>
          <v-row
            v-for="(answer, j) in question.answers"
            :key="j"
            align="center"
          >
            <v-col cols="7" sm="3" lg="2" class="pr-0">
              <v-select
                v-model="value[j]"
                class="mx-4"
                clearable
                label="Puntos"
                :items="selectableItems"
                solo
              ></v-select>
            </v-col>
            <v-col class="pl-0 text-left">
              <span class="mb-6 d-flex text-body-1 black--text">
                {{ answer }}
              </span>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-row>
  </v-col>
</template>

<script>
export default {
  name: "BelbinForm",
  props: {
    question: {
      type: Object,
      required: true,
    },
    value: {
      type: Array,
      required: true,
    },
  },
  computed: {
    selectableItems() {
      return [...Array(10).keys()].map((i) => i + 1);
    },
    inputVal: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
  watch: {
    inputVal: {
      handler() {
        if (this.inputVal.length === 0) {
          this.inputVal = [...Array(this.question.answers.length)];
        }
      },
      immediate: true,
    },
  },
};
</script>

<style scoped>
span {
  word-break: break-word;
}
</style>
