<template>
  <v-container>
    <v-col
      cols="12"
      md="10"
      class="px-0"
      style="margin-left: auto; margin-right: auto"
    >
      <v-card>
        <v-card-title>
          <v-col cols="12" class="pb-0">
            <v-row justify="center">
              <span class="text-h3">TEST <br /></span>
            </v-row>
            <v-row justify="center">
              <p class="text-h5">¿Qué animalito eres?</p>
            </v-row>
          </v-col>
        </v-card-title>
        <v-card-text v-if="showLink" class="mt-5 text-center black--text">
          <p class="text-h6">
            Instrucciones para hacer el test:
            <a href="https://youtu.be/8v2o_fabaaE" target="_blank">
              https://youtu.be/8v2o_fabaaE
            </a>
          </p>
        </v-card-text>
      </v-card>
    </v-col>
  </v-container>
</template>

<script>
export default {
  name: "Instructions",
  props: ["showLink"],
};
</script>

<style>
.v-pagination__navigation {
  display: none !important;
}
</style>
