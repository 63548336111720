import axios from "axios";

export default {
  async find(key, options) {
    return await axios.get(
      `${process.env.VUE_APP_SERVER_URL}results/${key}`,
      options
    );
  },
  async save(key, entity) {
    return (
      await axios.post(
        `${process.env.VUE_APP_SERVER_URL}results/${key}`,
        entity
      )
    ).data;
  },
};
