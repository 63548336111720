<template>
  <v-container class="d-block" fluid>
    <instructions :show-link="user != null"></instructions>
    <profile-form v-if="!user" @created="changeToTest"></profile-form>
    <main-view :user="user" v-else></main-view>
  </v-container>
</template>

<script>
import Instructions from "@/components/Instructions";
import MainView from "@/views/belbin-test/MainView";
import ProfileForm from "../ProfileForm.vue";

export default {
  name: "BelbinTest",
  components: {
    ProfileForm,
    Instructions,
    MainView,
  },
  data() {
    return {
      user: null,
    };
  },
  methods: {
    /**
     * This method is executed when the user is created in the profile form.
     * The user is saved in the variable 'user', and then the test is displayed.
     * @param user
     */
    changeToTest(user) {
      this.user = user;
    },
  },
};
</script>
