import axios from "axios";

export default {
  async findByKey(key) {
    return (
      await axios.get(`${process.env.VUE_APP_SERVER_URL}questionnaires`, {
        params: { key: key },
      })
    ).data;
  },
};
