<template>
  <v-container>
    <v-progress-linear
      color="light-blue"
      height="10"
      indeterminate
      striped
    ></v-progress-linear>
    <h3>{{ label }}</h3>
  </v-container>
</template>

<script>
export default {
  name: "LoadingPage",
  props: ["label"],
};
</script>

<style scoped>
h3 {
  text-align: center;
}
</style>
