<template>
  <v-container>
    <v-row class="my-4" justify="center">
      <v-col cols="10" class="d-inline-block">
        <v-card v-if="!isLoading" width="100%">
          <v-card-title>
            <v-row justify="center">
              <span class="mt-4 text-h4"> Datos personales </span>
            </v-row>
          </v-card-title>
          <v-card-text class="mt-4">
            <span style="font-size: 16px">
              Antes de comenzar con el test, es necesario que introduzcas tus
              datos personales
            </span>
            <v-form ref="form">
              <v-row class="mt-4" justify="center" dense>
                <v-col cols="12" md="5" xl="3">
                  <v-text-field
                    v-model="user.name"
                    class="required"
                    label="Nombre"
                    :rules="[(v) => !!v || 'Debes indicar un nombre']"
                    solo
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row justify="center" dense>
                <v-col cols="12" md="5" xl="3">
                  <v-text-field
                    v-model="user.surname"
                    class="required"
                    label="Apellido/s"
                    :rules="[(v) => !!v || 'Debes indicar un apellido']"
                    solo
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row justify="center" dense>
                <v-col cols="12" md="5" xl="3">
                  <v-text-field
                    v-model="user.phone"
                    class="required"
                    label="WhatsApp (con prefijo ej. +34)"
                    :rules="[
                      (v) => !!v || 'Debes indicar un número de teléfono',
                      (v) =>
                        regex.PHONE.test(v) ||
                        'El formato del WhatsApp es incorrecto, debes indicar el prefijo del país. Ej. +34123456789',
                    ]"
                    solo
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row justify="center" dense>
                <v-col cols="12" md="5" xl="3">
                  <v-text-field
                    v-model="user.email"
                    class="required"
                    label="Email"
                    :rules="[
                      (v) => !!v || 'Debes indicar un email',
                      (v) =>
                        regex.EMAIL.test(v) ||
                        'El formato del email es inválido',
                    ]"
                    solo
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row justify="center" dense>
                <v-col cols="12" md="5" xl="3">
                  <v-radio-group
                    v-model="user.type"
                    class="user-type-rb"
                    label="¿Quién eres?"
                    :rules="[
                      (v) =>
                        !!v || 'Debes indicar si eres empresa o profesional',
                    ]"
                  >
                    <v-radio
                      label="Emprendedor/a o empresa"
                      value="business"
                    ></v-radio>
                    <v-radio
                      label="Profesional (AV u otro)"
                      value="professional"
                    ></v-radio>
                  </v-radio-group>
                </v-col>
              </v-row>
              <v-row justify="center" dense>
                <v-col cols="12" md="8" xl="8">
                  <span style="font-size: 18px"
                    >Antes de enviar tu consulta, échale un vistazo a la
                    información básica sobre protección de datos de AITED
                    <span
                      style="color: purple; cursor: pointer"
                      @click="displayPrivacyInfo"
                    >
                      AQUÍ
                    </span>
                  </span>
                  <v-alert
                    v-show="showPrivacyInfo"
                    outlined
                    text
                    style="text-align: left; font-size: 13px"
                    transition="slide-y-transition"
                  >
                    <span>
                      AITED te informa que los datos de carácter personal que
                      nos proporciones rellenando el presente formulario, serán
                      tratados por DIGITAL HAPPY LIFESTYLE SLU como responsable
                      de esta web. Finalidad: Responder a tu consulta y
                      remitirte información sobre productos y servicios propios
                      o de terceros. Se mantendrán mientras no solicites su
                      cancelación. Destinatarios: Google y Mailerlite. Puedes
                      consultar sus políticas de privacidad en los Links de más
                      abajo. Legitimación: Consentimiento del/la interesado/a.
                      Derechos: Podrás ejercer tus derechos de acceso,
                      rectificación, limitación y suprimir los datos en
                      hola@somosaited.org, así como el derecho a presentar una
                      reclamación ante una autoridad de control. Puedes
                      consultar la información adicional y detallada sobre
                      Protección de Datos en nuestra
                      <b
                        ><a
                          href="https://somosaited.org/privacidad/"
                          target="_blank"
                          style="color: black; text-decoration: none"
                          >política de privacidad.</a
                        ></b
                      >
                      <br /><br />
                      <b>GOOGLE:</b> Puedes consultar su política de privacidad
                      en el siguiente enlace:
                      <a
                        href="https://policies.google.com/privacy"
                        target="_blank"
                        >https://policies.google.com/privacy</a
                      >
                      <br /><br />
                      <b>MAILERLITE:</b> Puedes consultar su política de
                      privacidad en el siguiente enlace:
                      <a
                        href="https://www.mailerlite.com/legal/privacy-policy"
                        target="_blank"
                        >https://www.mailerlite.com/legal/privacy-policy</a
                      >
                    </span>
                  </v-alert>
                </v-col>
              </v-row>
              <v-row justify="center" dense>
                <v-col cols="12" md="5" xl="3">
                  <v-checkbox
                    class="required"
                    :rules="[
                      (v) =>
                        !!v ||
                        'Debes aceptar la política de privacidad para poder continuar',
                    ]"
                  >
                    <template v-slot:label>
                      <span>
                        Estoy de acuerdo con la
                        <b>política de privacidad.</b>
                      </span>
                    </template>
                  </v-checkbox>
                </v-col>
              </v-row>
            </v-form>
            <v-row class="my-4" justify="center">
              <v-btn color="primary" :loading="loading" @click="startTest">
                ¡Quiero hacer el test!
              </v-btn>
            </v-row>
          </v-card-text>
        </v-card>

        <v-card v-else>
          <loading-page label="Cargando formulario..."></loading-page>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import LoadingPage from "@/components/LoadingPage";
import GoogleAPIRepository from "../repositories/GoogleAPIRepository";
import regex from "@/common/regex";

export default {
  name: "ProfileForm",
  components: { LoadingPage },
  data() {
    return {
      user: {},
      loading: true,
      regex,
      showPrivacyInfo: false,
    };
  },
  computed: {
    isLoading() {
      return this.loading;
    },
  },
  created() {
    if (localStorage.getItem("user")) {
      this.user = JSON.parse(localStorage.getItem("user"));
      this.$emit("created", this.user);
    }
    this.loading = false;
  },
  methods: {
    displayPrivacyInfo() {
      this.showPrivacyInfo = !this.showPrivacyInfo;
    },
    startTest() {
      if (this.$refs.form.validate()) {
        GoogleAPIRepository.saveUser(this.user).then(() => {
          localStorage.setItem("user", JSON.stringify(this.user));
          this.$emit("created", this.user);
        });
      }
    },
  },
};
</script>

<style scoped>
span {
  word-break: break-word;
}
</style>
<style>
.user-type-rb .v-label {
  font-size: 16px !important;
}
</style>
