<script>
import { Bar } from "vue-chartjs";

export default {
  name: "ResultsChart",
  extends: Bar,
  props: ["data", "options"],
  watch: {
    data: {
      handler() {
        this.renderChart(this.data, this.options);
      },
      deep: true,
    },
  },
  mounted() {
    this.renderChart(this.data, this.options);
  },
};
</script>

<style scoped></style>
